.bottom.section-padding {
  /* margin-top: 3em; */
  background: #00f 0% 0% no-repeat padding-box;
  border-top: 4px solid #fb3b0a;
  width: 100%;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  color: #fff;
}
.copyright p {
  padding: 0;
  margin: 0;
  font: normal normal normal 9px/24px Roboto;
  letter-spacing: 0.13px;
  color: #c2c2c2;
}
.mainpage_navTab {
  /* min-height: 90vh; */
}
