.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}



.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.modal-card-body .deleteBtn{
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background:transparent;
  border-radius: 18px;
  border: none !important;
}
.modal-card-head{
      padding: 10px 20px;
}
.modal-card-head button.deleteBtn{
      border: none;
    background: transparent;
}
.popup-content {
    background: transparent !important;
    border: 0 !important;
}
.popup-overlay {
    z-index: 9999 !important;
}

select.form-control {
    background-image: url(../../../assets/images/arrow-new.png) !important;
    background-size: 22%;
    background-position: right center;
}
canvas.react-pdf__Page__canvas {
    width: 100% !important;
}
.uploadFrm{display: flex;  justify-content: start !important; align-items: center;}
.helpupload {     text-align: left; align-items: center; padding: 10px 6em 0 2.2em; }
.helpupload{width:40px !important;}
.helpupload .leftHelpBottom{left: 49px; top: 9px;}


.downloadmodalbtn {
    text-align: center;
    padding: 0;
    margin: 0;
}

.downloadmodal .modal-card {
    width: 610px;
  }

.downloadmodalbtn button {
    width: 242px;
    padding: 27px 0;
    font-size: 16px;
}

.downloadmodal .upl-file-row.upl-file-sec {
    margin-top: 4em;
    margin-bottom: 4em;
}

/*@media screen and (max-width:1190px){
.helpupload .leftHelpBottom {
    left: 6em;
}
.helpupload {
    padding: 10px 6em 0 2.2em;
  }
  .uploadFrm{padding-left: 7em;}
}*/
#Login input {
      align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}
.save-btn-row{
    margin: 0 auto;
    text-align: center;
}
.save-btn-row .btn{
    width:150px;
}

.save-modl-popup .yellow-btn {
    padding: 6px 10px;
}
.popup-det-alrt h4{
text-align: center;
}
.col-lg-12.Alert-btn {
    /* margin: 0 auto; */
    text-align: center;
}
.popup-det-alrt .Alert-btn .btn {
    min-width: 150px;
    border-radius: inherit;
}

.popup-det-alrt .Alert-btn-setting .btn {
    min-width: 150px;
    border-radius: inherit;
    margin:3px;
}

.Alert-btn {
    margin: 2rem auto 1rem;
}

.Alert-btn .btn-danger{
    margin-right: 1rem;
}
@media screen and (min-width: 769px), print {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 800px;
  }
}





/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
    background-color: #fff;

  }
  .header .navbar-wrap{
    height: 78px;
  background: transparent linear-gradient(180deg, #0909FE 0%, #262CFDFD 41%, #6272FBFA 100%) 0% 0% no-repeat padding-box;
  border-top: 9px solid #EC4C1D;
  opacity: 1;
  transition: all 0.5s;
  z-index: 997;
}

  .header #logo h1 {
    font-size: 42px;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }

.top-header .left-logo img {
    max-width: 350px;
}
.top-header {
    height: 55px;
    border-bottom:1px solid #e3e3e6;
}
.top-header  .left-logo {
    text-align: center;
    width: 100%;
}
.top-header .top-wrap {
    position: relative;
}
.top-header .right-box {
   float: right;
    display: flex;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    z-index: 1;
}
.top-header .right-box .nav-right {
    list-style: none;
    display: inline-flex;
    gap: 4px;
    margin: 0;
    justify-content: center;
    align-items: center;
}
.top-header .nav-right li img {
    height: 30px;
    width: 30px !important;
}
.top-header .nav-right li {
    border-right: 0px solid #cecece !important;
    width: 60px;
    height: 54px;
    background: #fff;
    line-height: 50px;
    text-align: center;
    border-radius: 0;
}
.top-header .nav-right li:first-child {
  border-left: 0px solid #cecece !important;
}
.top-header .nav-right li a {
    display: block;
}
.scrolled-offset {
    margin-top: 70px;
  }

.ant-dropdown-menu .ant-dropdown-menu-item .anticon {
  font-size: 20px;
}
.anticon {
  vertical-align: 2px !important;
}

.main-menu {
  margin-top: 2px;
  position: absolute;
  z-index: 999999;
  text-align: left;
}

/*
top header */

  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation
  */


  .navbar-wrap .navbar {
    padding: 0;
  }

  .navbar-wrap .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex: 1 0 100%;
    gap: 12px;
  }

  .navbar-wrap .navbar li {
    position: relative;
    flex: 1;
  }

  .navbar-wrap .navbar a {
    display: flex;
    justify-content: space-evenly;
    white-space: nowrap;
    transition: 0.3s;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #ffffff;
    opacity: 0.88;
    font: normal normal bold 19px/23px Gotham-Bold;
    font-weight: normal;
    padding: 0;
    height: 69px;
    line-height: 68px;
    padding-left: 8px;
    padding-right: 8px;

  }

  .navbar-wrap .navbar a i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar-wrap .navbar a:hover,  .navbar-wrap .navbar .active,  .navbar-wrap .navbar li:hover > a {
    color:#ffffff;
    background: #EC4C1D
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #50d8af;
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }



  /**
  * Mobile Navigation
  */
  .mobile-nav-toggle {
    color: #0c2e8a;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }

  .mobile-nav-toggle.bi-x {
    color: #fff;
  }


  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(8, 30, 91, 0.9);
    transition: 0.3s;
    z-index: 999;
  }

  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #0c2e8a;
  }

  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #50d8af;
  }

  .navbar-mobile .getstarted {
    margin: 15px;
  }

  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }

  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }

  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }

  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }

  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #50d8af;
  }

  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  .top-header .nav-right li a {
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 3px;
    color: #00f;
  }
  .switch-btn {
    margin-top: 6px !important;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
    .navbar {
        justify-content: flex-end;
    }
    .navbar .fa-bars{
    color: #fff;
    }
    .main-menu {
      margin-top: 2px;
      position: absolute;
      z-index: 999;
      text-align: left;
      top: -6px;
      left: 0;
    }
    .top-header .left-logo img {
      max-width: 250px;
      text-align: left;
      float: left;
      margin: 0 0 0 87px;
    }
  }

  @media (max-width: 768px) {
    .header {
      height: 60px;
    }
    .header #logo h1 {
      font-size: 34px;
    }
    .header #logo img {
      max-height: 40px;
    }
   
  }

  @media only screen and (max-width: 767px) {
    .top-header .right-box {
      position: absolute !important;
      justify-content: center !important;
      width: 299px !important;
      float: left;
      top: 48px;
      left: auto;
      right: 20px !important;
      z-index: 99999;
    }
    .main-menu {
      top: 56px;
      left: 0;
      width: 49px;
      z-index: 999;
    }
    /* .main-menu div {
      width: 49px;
    } */
    .top-header {
      height: 110px !important;
    }
    .top-header .left-logo {
      padding-top: 0px !important;
      width: 100% !important;
      text-align: center !important;
    }
    .top-header .left-logo img {
      max-width: 245px !important;
      margin-top: 10px;
      text-align: left;
    }
    .top-header .nav-right li {
      width: auto !important;
      padding: 0 4px;
    }
  }

 .right-box .nav-right li > .dropdown {
    position: relative;
}
.right-box .nav-right ul.dropdown {
  position: absolute;
  z-index: 999;
  width: 102px !important;
  top: 50px;
  margin-left: 0;
  padding-left: 0;
  border: 1px solid #ddd;
}
.right-box .nav-right ul.dropdown li {
    width: 100px;
    height: 40px;
    line-height: 1.5;
    border: 0;

}

.right-box .nav-right ul.dropdown li:hover {
  background: #1677ff !important;
}
.right-box .nav-right ul.dropdown li button {
    border: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding: 0;
    line-height: 1.5;
    height: 100%;
    font-size: 14px;
    color: #333;
}
.right-box .nav-right ul.dropdown li:hover button{
  color: #FFFFFF;
}


.top-header .nav-right li {
  	border-right: 0px solid #CECECE !important;
    width: 68px;
    height: 45px;
    background: #fff;
    line-height: 42px;
    text-align: center;
    border-radius: 0;
}

.topButtonSignIn {
   text-align: center;
    height: 7px;
    margin: 0;
}

.topButtonSignIn .signinbtn {
    padding: 0;
    height: 54px;
    border-radius: 0;
    width: 70px;
    color: #1E1EFF;
    text-align: center;
    margin-left: -3px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 14px;
    background: transparent;
    border: none;
}
.topButtonSignIn .signinbtn:hover,
.topButtonSignIn .signinbtn:focus,
.topButtonSignIn .signinbtn:active {
    background: #1e1eff;
    color: #fff;
    outline: none;
}
