.navTabList .navbarpanel{
    min-height: 50px;
    /*border-top: 3px solid #EC4C1D;*/
    background: rgb(255 255 255) !important;
}

.react-tabs ul.navbarpanel{
    padding: 0;
       margin: 0 auto;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  margin-top: 0px;
}

.react-tabs__tab-list {
   background: #333333 !important;
   padding: .3rem 1rem;
}

.react-tabs__tab {
    letter-spacing: .03em;
    padding: 0;
    text-align: center;
    line-height: 52px !important;
    bottom: 0px;
    position: relative;
    list-style: none;
    cursor: pointer;
    color: #4c4c4c !important;
    opacity: 0.88;
    font: normal normal bold 16px/23px Gotham-Bold;
    margin-top: -1px;
    padding-left: 0;
    padding-right: 0;
    flex-grow: 1;
}

.react-tabs__tab--selected {
    color: #fff;
    font-weight: 900;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab--selected, .react-tabs__tab:hover, .react-tabs__tab:focus, .react-tabs__tab:active {
/*  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);*/
  outline: none;
  background: #0000ff;
  outline: none;
  background: #0000ff;
  box-shadow: none !important;
  color: #fff !important;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
      border-top: 1px solid #e3e3e6;
}
.midChatSectonBtn{
  margin-top: 3em;
}


@font-face {
    font-family: 'Gotham-Bold';
    src: url('../../assets/fonts/GothamBold.otf'); /* IE9 Compat Modes */
   }
   @font-face {
    font-family: 'GothamBook';
    src: url('../../assets/fonts/GothamBook.otf'); /* IE9 Compat Modes */
   
}
@font-face {
    font-family: 'GothamUltra';
    src: url('../../assets/fonts/GothamUltra.otf'); /* IE9 Compat Modes */
   }
   @font-face {
    font-family: 'GothamUltraItalic';
    src: url('../../assets/fonts/GothamUltraItalic.ttf'); /* IE9 Compat Modes */
   }


   @media only screen and (min-width : 1200px) {
    .container, .react-tabs ul.navbarpanel { max-width: 1363px; } 
}



/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/




@media only screen and (min-width : 1200px) {
  .container { max-width: 1363px; } 
}

@media (max-width: 1400px) {
  .img-fluid {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
  left: -90%;
}
.navbar .dropdown .dropdown:hover > ul {
  left: -100%;
}
.react-tabs__tab {
         padding-left: 0;
          padding-right: 0;
          flex-grow: 1;
      }
.react-tabs ul.navbarpanel {
      padding-left: 15px;
      padding-right: 15px;
}
  }




  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
    .navbar {
        justify-content: flex-end;
    }
    .navbar .fa-bars{
    color: #fff;
    }
  }

  @media (max-width: 768px) {
    .header {
      height: 60px;
    }
    .header #logo h1 {
      font-size: 34px;
    }
    .header #logo img {
      max-height: 40px;
    }
  }