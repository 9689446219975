.navTabList .navbarpanel {
  min-height: 40px;
  /*border-top: 3px solid #EC4C1D;*/
  background: rgb(255 255 255) !important;
}

/*.react-tabs ul.navbarpanel{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
}*/

.react-tabs ul.navbarpanel {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 15px !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
  margin-top: 0px;
}

.react-tabs__tab-list {
  /* border-bottom: 1px solid #aaa; */
  /* margin: 0 0 10px; */
  /* padding: 0; */
  background: #333333 !important;
  padding: 0.3rem 1rem;
}

.react-tabs__tab {
  letter-spacing: 0.03em;
  padding: 0;
  text-align: center;
  line-height: 40px !important;
  bottom: 0px;
  position: relative;
  list-style: none;
  cursor: pointer;
  color: #4c4c4c !important;
  opacity: 0.88;
  font: normal normal bold 15px/23px Gotham-Bold;
  margin-top: -1px;
  padding-left: 0;
  padding-right: 0;
  flex-grow: 1;
  transition: 0.3s;
}

.react-tabs__tab--selected {
  color: #fff;
  font-weight: 900;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab--selected,
.react-tabs__tab:hover,
.react-tabs__tab:focus,
.react-tabs__tab:active {
  /*  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);*/
  outline: none;
  outline: none;
  background: rgba(0,0,255,0.8);
  box-shadow: none !important;
  color: #fff !important;
 
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  border-top: 1px solid #e3e3e6;
}
.midChatSectonBtn {
  margin-top: 3em;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("../../assets/fonts/GothamBold.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "GothamBook";
  src: url("../../assets/fonts/GothamBook.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "GothamUltra";
  src: url("../../assets/fonts/GothamUltra.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "GothamUltraItalic";
  src: url("../../assets/fonts/GothamUltraItalic.ttf"); /* IE9 Compat Modes */
}

@media only screen and (min-width: 1200px) {
  .container,
  .react-tabs ul.navbarpanel {
    max-width: 1363px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1363px;
  }
}

@media (max-width: 1400px) {
  .img-fluid {
    max-width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
  .react-tabs__tab {
    padding-left: 0;
    padding-right: 0;
    flex-grow: 1;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1320px) {
  .react-tabs__tab {
    padding-left: 14px;
    padding-right: 15px;
    flex-grow: inherit;
  }
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
  .navbar {
    justify-content: flex-end;
  }
  .navbar .fa-bars {
    color: #fff;
  }
}

@media (max-width: 768px) {
  .header {
    height: 60px;
  }
  .header #logo h1 {
    font-size: 34px;
  }
  .header #logo img {
    max-height: 40px;
  }
}
