.userProfileForm {
    max-width: 1300px !important;
    padding:20px 0; background: transparent;
    border: 0; margin:0 auto;
    border: Solid 6px #bdbdff;
    padding: 20px 40px;
    margin-bottom: 20px;
    background: #f4f1ff;
    }
    .update-pro button {
    margin-top: 28px;
    padding: 15px;
    font-weight: bold;
    text-transform: uppercase;
    border: solid 4px #adadff;
}

    .userProfileForm #Login input{max-width:100%; border:0;}
    .custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition:
    top 0.3s ease,
    color 0.3s ease,
    font-size 0.3s ease;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #222;
}

.custom-field .error-message {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  background: #d30909;
  color: #fff;
  height: 24px;
}

.custom-field .error-message:empty {
  opacity: 0;
}
/* TWO */
.custom-field.two input {
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background:
    linear-gradient(90deg, #8f8ffc, #1c1ca2) center bottom/0 0.15em no-repeat,
    linear-gradient(90deg, #ccc, #ccc) left bottom/100% 0.15em no-repeat,
    linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat;
  transition: background-size 0.3s ease;
}

.custom-field.two input.dirty,
.custom-field.two input:not(:placeholder-shown),
.custom-field.two input:focus {
  background-size: 100% 0.15em, 100% 0.1em, 100%;
}


.themeCodePick {
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px #ccc;
    padding-top: 19px;
    margin-top: 8px;
    border: 0;
    align-items: center;
}

.page-content.inset-profile {
    max-width: 1300px;
    width: 100%;
    background: #F6F3FF;
    border: solid 1px #0000FF;
    margin-top: 6em;
    margin: 6em auto;
    border: 0 !important;
    background: transparent;
}

.userProfileForm #Login input {
    max-width: 100%;
    border: 0;
    color: #666;
}
.oneInp2checkbox {
    display: flex !important;
    align-items: center;
}

.oneInp2checkbox input {
    width: 50px !important;
    margin: 0 !important;
}