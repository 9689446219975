/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

body{
  font-family: GothamBook;
}

.hero {
  width: 100%;
  height: 500px;
  background: #fafaff;
  border-bottom: 1px solid #c5cbfffa;
  
}

.hero .container {
  padding-top: 0px;
}

.hero h1 {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  color: #0f394c;
  text-transform: uppercase;
}

.hero h2 {
  color: #1c698c;
  margin: 10px 0 0 0;
  font-size: 20px;
}

.hero .btn-more {
  font-family: Gotham-Bold;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.13px;
  color: #FFFFFF;
  opacity: 1;
  background: #EC4C1D 0% 0% no-repeat padding-box;
  border-radius: 27px;
  text-decoration: none;
  margin-top: 1em;
  width: 200px;
  height: 53px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.hero .btn-more:hover {
  background: #de3a0a 0% 0% no-repeat padding-box
}

.list-view-row .image-icon {
  width: 70px;
  height: 100px;
  opacity: 0.9;
}
.image-icon img{
  width: 78px;
  padding-top: 1em;
}

.list-view-row h4{
  text-align: left;
  font: normal normal bold 37px/61px Gotham-Bold;
  letter-spacing: 0.26px;
  color: #343AFA;
  font-weight: bold;
  margin: 0;
}
.list-view-row h5{
  text-align: left;
  font: normal normal normal 45px/61px GothamBook;
  letter-spacing: 0.36px;
  color: #343AFA;
  font-family: 'GothamBook';
}
.list-view-row .box-title p{
  text-align: left;
  font: normal normal normal 20px/38px GothamBook;
  letter-spacing: 0.17px;
  color: #A1A1A1;
  opacity: 1;
}

.heroLeftCol {
  display: flex;
  justify-content: flex-start;
   padding-top: 2em;
}
.heroLeftCol .col-icon {
  width: 150px;
}
.heroLeftCol .col-text {
  width: calc(100% - 150px);
}

.col-icon ul {
  list-style: none;
  padding: 0;
}
.col-icon ul li {
  margin-bottom: 4em;
}





@media (max-width: 991px) {
  .hero {
    height: 100vh;
    text-align: center;
  }
  .hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .hero .hero-img img {
    width: 80%;
  }
}

.midprocessSection{
    padding-top: 0em;
  
}
.midprocesscont {
    display: flex;
    justify-content: center;
    gap:15px;
}

.midprocesscont .text-top-tilte {
    text-align: center;
}
.presto-aiml .dat-img{
    height: 230px;
}
.midprocesscont .dat-img img {
    margin-top: 1em;

}
.presto-ar-wrap .text-bottom-tilte{
    text-align: right;
    font: normal normal normal 16px/24px GothamBook;
    letter-spacing: 0.1px;
    color: #2000FF;
    padding-right: 35px;
    margin-bottom: 1em;
}

.presto-ar-full {
    display: flex;
    text-align: center;
    justify-content: center;
}
.presto-ar-left {
    padding-top: 108px;
}
.presto-ar-full img {
    text-align: center;
    max-width: 1190px;
    width: 100%;
}

.botprocessSection{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 2.5em;
}
.btn-footer {
    text-align: center;
    margin-top: 2.5em;
}
.btn-orange {
    font-family: Gotham-Bold;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.13px;
    color: #FFFFFF;
    opacity: 1;
    background: #FB3B0A 0% 0% no-repeat padding-box;
    border-radius: 40px;
    width: 198px;
    height: 60px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
.btn-orange:hover {
    background: #de3a0a 0% 0% no-repeat padding-box;
    text-decoration: none;
    color: #FFFFFF;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
    max-width: auto;
    max-width: none;
}
.hero-img {
    display: flex;
    justify-content: flex-end;
}
