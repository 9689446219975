
.invoice-list {
  display: grid;
}

.invoice-list-header, .invoice-list-item {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.invoice-list-header {
  background-color: #e7e7f7;
  font-weight: bold;
  padding: 4px 12px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.invoice-list-item {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding:0 12px;
}

.invoice-list-item:nth-child(even) {
  background-color: #f6f6f6;
}

.invoice-column {
  padding: 8px;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff1a1a;
}
.L-H-34 { line-height: 34px;}
.blue-btn { 
  background-color: rgb(0, 0, 255) !important;
  border-radius: 0;
  padding: 4px 10px;
  color: #fff;
  margin-right: 10px;
}
.blue-btn:hover, .blue-btn:active, .blue-btn:focus {
  color: #fff;
}
.h2-page-title {
  margin-bottom: 20px;
  color: #00f;
}
.col_1 {
  width: 50%;
}
.col_2 {
  width: 30%;
}
.col_3 {
  width: 30%;
}
@media (max-width: 768px) {
  .invoice-list-header, .invoice-list-item {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .invoice-column {
    padding: 10px;
  }
}
