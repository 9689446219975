.infinite-scroll-component{overflow:hidden !important;}
.chartLink {
    color: #fff;  margin-top: 1em;
}
.boxchart{
  border: solid 1px #ccc;
  padding: 21px;
}
.chartLink a {
        background: #00f;
    color: #fff;
    padding: 8px 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 4px; text-decoration:none;
}
.chartLink a:hover{
background:#d60c0c;
}

.chart-repeat-div.row {
    justify-content: center;
}
.chartjs-render-monitor {
   cursor: pointer;
}
.my-chart-img {
    max-width: 1395px;
    width: 100% !important;
}
.my-chart-img{
    display: block;
    text-align: center;
    position: relative;
    margin: auto;
}
 .chart-repeat-div .firstChart {
 	position: relative;
    border: solid 21px #fff;
    padding-bottom: 44px;
      overflow: hidden;
}
.zippg {
    /*background: #eee;*/
}
.left-side-boxxx{
  width: 100%;
  /*height: calc(100vh - 130px);*/
  /* min-height: 350px; */
   border: 1px solid #9999;
       overflow: auto;
      display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
/*html {
    overflow: hidden;
}*/

.blue_color {
  color: #0000B3;
}

.loadmore:hover{
    background: #0000B3;
    color: #fff;
}

.loadmore{
  background: #0000B3;
  color: #fff;
  margin-bottom: 1em;
}
.loadMorePanel{display: flex;
    justify-content: center;
}
.selectItemNo {
    margin-left: 11px;
}
.resp-scrollableDiv{
    height: 516px;
    overflow-y: auto;
}

 /* // Extra small devices (portrait phones, less than 576px) */
 @media (max-width: 575.98px) { 
    .resp-scrollableDiv{
        /* background: rgb(0, 255, 255); */
    }
  }
 
 /* // Small devices (landscape phones, 576px and up) */
 @media (min-width: 576px) and (max-width: 767.98px) { 
    .resp-scrollableDiv{
        /* background: rgb(255, 60, 0); */
        height: 356px;
    }
  }
 
 /* // Medium devices (tablets, 768px and up) */
 @media (min-width: 768px) and (max-width: 991.98px) { 
    .resp-scrollableDiv{
        /* background: rgb(4, 255, 0); */
        height: 242px;
    }
  }
 
 /* // Large devices (desktops, 992px and up) */
 @media (min-width: 992px) and (max-width: 1199.98px) { 
    .resp-scrollableDiv{
        /* background: rgb(255, 221, 0); */
        height: 296px;
    }
  }
 
 /* // Extra large devices (large desktops, 1200px and up) */
 @media (min-width: 1200px) { 
    .resp-scrollableDiv{
        /* background: #d0ff00; */
        height: 414px;
    }
  }

  @media (min-width: 1300px) { 
    .resp-scrollableDiv{
        /* background: #b5b5b5; */
        height: 500px;
    }
  }

  @media (min-width: 1400px) { 
    .resp-scrollableDiv{
        /* background: #1e1e1e; */
        height: 474px;
    }
  }
  @media (min-width: 1500px) { 
    .resp-scrollableDiv{
        /* background: #926060; */
        height: 533px;
    }
  }

@media only screen and (max-width: 767px){
    /* .resp-scrollableDiv{
        background: #00f;
        height: 300px;
    } */

    .chart-repeat-div .firstChart {
    overflow: hidden;
    overflow-x: scroll;
    float: left;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    float: none;
    margin: 15px;
}
.react-tabs__tab {
    padding: 6px 5px;
    font-size: 13px;
}
.chart-img {
    width: 100%;
    width: 350px !important;
    height:440px !important;
}
.custom-filter .styled-select.arrows {
    max-width: 300px;
    width:100%;
    margin: 0 auto;
    float: none;
}
.custom-model-popup .modal-card {
    width: 355px;
}
.col-md-11.col-lg-11.pl-0.custom-filter {
    width: 100%;
}
.downloadicon {
    height: 32px;
 }
}
