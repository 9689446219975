.resetzoomicon {
      border: 0;
    background: transparent;
    text-align: left;
    width: 27px;
    margin: 0;
    position: absolute;
     left: -18px;
    top: 0px;
  }
.resetzoomicon img{
   width: 30px;
 }
 span.chartIndexDisplay {
   float: right;
  font-weight: bold;
  position: absolute;
  right: -36px;
  bottom: -20px;
  color: #999;
}
 @media only screen and (max-width: 1737px){
.resetzoomicon img {  }
.resetzoomicon {
    left: -24px;
}
}
